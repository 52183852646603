import { observer } from "mobx-react-lite";
import React from "react";
import RootStore from "stores/RootStore";
import { Slide } from "@mui/material";

const PrecipitationErrorMessage = observer((props) => {
  const { precipitationWMSError } = RootStore.regionPrecipitationStore;
  const { activeWidgetId } = RootStore.uiStore;

  return (
    <Slide
      direction="right"
      in={precipitationWMSError && activeWidgetId === "precipitation"}
      timeout={1500}
    >
      <div
        id="precipitationErrorMessage"
        style={{
          padding: "5px",
          top: 60,
          borderRadius: "3px",
          left: "240px",
          margin: "auto",
          position: "absolute",
          zIndex: "444444",
          backgroundColor: "rgb(255, 217 ,217 ,0.9)",
          color: "red",
        }}
      >
        {/* <p style={{ margin: '0' }} > Der WMS-Layer könnte nicht aktualisiert werden</p> */}
        <p style={{ margin: "0" }}> Keine Rasterdaten vorhanden </p>
      </div>
    </Slide>
  );
});

export default PrecipitationErrorMessage;
