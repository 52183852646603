import TimeSlider from "@arcgis/core/widgets/TimeSlider";
import TimeWMSLayer from "libs/TimeWMSLayer";
import TimeWMSrasterVlaues from "libs/TimeWMSrasterVlaues";
import { action, makeObservable, observable } from "mobx";
import ReactDOM from "react-dom";
import * as projection from "@arcgis/core/geometry/projection";
class RegionPrecipitationStore {
  timeseries = [
    {
      selectlabel: "Kein Gebietsniederschlag",
      deactive: true,
      tsid: -1,
    },
    {
      tsid: 48556042,
      timeoffset: 86400000,
      _timeoffset: "24*60*60*1000",
      label: "1h-Summen am",
      selectlabel: "1h-Summen",
      dateformat: "d.MM.yyyy HH:mm",
      legend: "_48556042",
    },
    {
      tsid: 48557042,
      timeoffset: 604800000,
      _timeoffset: "7*24*60*60*1000",
      label: "24h-Summen (gleitend) am",
      selectlabel: "24h-Summen (gleitend)",
      dateformat: "d.MM.yyyy HH:mm",
      legend: "_48190042",
    },
    {
      tsid: 49489042,
      timeoffset: 5184000000,
      _timeoffset: "60*60*60*1000",
      label: "72h-Summen (gleitend) am",
      selectlabel: "72h-Summen (gleitend)",
      dateformat: "d.MM.yyyy HH:mm",
      legend: "_49489042",
    },
    {
      tsid: 48193042,
      timeoffset: 31536000000,
      _timeoffset: "365*60*60*1000",
      selectlabel: "Monatssummen",
      label: "Monatssummen für",
      dateformat: "MMMM y",
      _dateformat: "F Y",
      legend: "_48193042",
    },
  ];
  url = "https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?";
  colorClassificationBaseLink =
    "https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?datasource=0&service=kisters&type=queryServices&request=getColorClassifications&format=json&name=CombiPrecip$name&datasource=0&service=kisters&type=queryServices";
  selectedTS = this.timeseries[0];
  wmsLayerOpacity = 50;
  todayDate = new Date();
  precipitationDate = new Date();
  monthPrecipitationDate = new Date();
  wmsLayerDate = null;
  timeWMSLayer = new TimeWMSLayer(
    this.url,
    this.selectedTS.tsid,
    this.wmsLayerDate
  );
  lastWMSLayer = null;
  highlightBackground = "#F3F315";
  rgb = null;
  displayTypes = ["Statisch", "Animiert"];
  selectedDisplayType = this.displayTypes[0];
  lastTimeSlider = null;
  precipitationWMSError = false;
  loadingWMSLayer = false;
  timeWMSrasterVlaues = new TimeWMSrasterVlaues(
    "https://www.ag.ch/app/hydrometrie/kiwis/KiWIS?"
  );

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      selectedTS: observable,
      // timeWMSLayer: observable,
      // lastWMSLayer: observable,
      wmsLayerOpacity: observable,
      wmsLayerDate: observable,
      rgb: observable,
      selectedDisplayType: observable,
      precipitationDate: observable,
      monthPrecipitationDate: observable,
      precipitationWMSError: observable,
      loadingWMSLayer: observable,
      setSelectedTS: action,
      handleTransparency: action,
      handlePrecipitationDate: action,
      handleAnimationDate: action,
      handleWMSLayerDate: action,
      setRGB: action,
      handleDisplayTypeChange: action,
      setLastTimeSlider: action,
      setPrecipitationWMSError: action,
    });

    // watchUtils.whenOnce(this.timeWMSLayer, "loaded", (e) => {
    // console.log("watchUtils loaded", e);
    // this.timeWMSLayer.on("refresh", (e) => {
    //   console.log("refreshed");
    //   console.log(e);
    // });

    this.timeWMSLayer.on("refresh", (event) => {
      console.log("on refresh wmsLayer");
      this.setPrecipitationWMSError(false);
    });
    this.timeWMSLayer.on("layerview-create", (event) => {
      console.log("layerview-create");
      this.setPrecipitationWMSError(false);
    });
    this.timeWMSLayer.on("layerview-create-error", (event) => {
      console.log("layerview-create-error");
      this.setPrecipitationWMSError(true);
      // setTimeout(() => console.log(this.timeWMSLayer), 3000);
    });

    this.setMinutesAndSeconds(this.todayDate);
    this.todayDate.setHours(this.todayDate.getHours() - 1);
    this.setMinutesAndSeconds(this.precipitationDate);
    this.precipitationDate.setHours(this.precipitationDate.getHours() - 1);

    this.setMinutesAndSeconds(this.monthPrecipitationDate);
    this.monthPrecipitationDate.setDate(1);
    this.setHoursForMonthlyDate(this.monthPrecipitationDate);

    this.canvas = document.createElement("canvas");
  }

  setPrecipitationWMSError = (newBoolean) => {
    this.precipitationWMSError = newBoolean;
  };

  setMonthPrecipitationDate = (date) => {
    this.monthPrecipitationDate = date;
  };

  setMinutesAndSeconds = (date) => {
    date.setMinutes("00");
    date.setSeconds("00");
    date.setMilliseconds("00");
  };

  setHoursForMonthlyDate = (date) => {
    const timeZoneOffset = date.getTimezoneOffset();
    if (timeZoneOffset === -60) {
      date.setHours(0);
    } else if (timeZoneOffset === -120) {
      date.setHours(1);
    }
  };

  stopLastTimeSlider = () => {
    if (this.lastTimeSlider) {
      this.lastTimeSlider.stop();
    }
  };

  renderTimeSlider = (
    timeWMSrasterVlaues,
    wmsLayerDate,
    selectedTS,
    loadingElements,
    setShowTimeSlider
  ) => {
    const timeSliderHtmlElement = document.getElementById("timeSlider");
    ReactDOM.unmountComponentAtNode(timeSliderHtmlElement);
    // timeSliderHtmlElement.innerHTML = "";
    // timeSliderHtmlElement.replaceChildren("");
    const { isThreeDMode } = this.rootStore.mapStore;
    const view = isThreeDMode
      ? this.rootStore.mapStore.sceneView
      : this.rootStore.mapStore.mapView;
    ReactDOM.render(loadingElements, timeSliderHtmlElement);
    return new Promise((resolve, reject) => {
      timeWMSrasterVlaues.setToDate(wmsLayerDate);
      timeWMSrasterVlaues
        .load(selectedTS.tsid)
        .then(({ timestamps, tsId }) => {
          if (timestamps.length > 1) {
            const startDate = new Date(timestamps[0]).getTime();
            const endDate = new Date(
              timestamps[timestamps.length - 1]
            ).getTime();
            this.setPrecipitationWMSError(false);
            ReactDOM.unmountComponentAtNode(timeSliderHtmlElement);

            const timeSlider = new TimeSlider({
              timeVisible: true,
              layout: "compact",
              loop: true,
              playRate: 1500,
              mode: "instant",
              container: "timeSlider",
              // view: isThreeDMode
              //   ? this.rootStore.mapStore.sceneView
              //   : this.rootStore.mapStore.mapView,
              view,
              // view: this.rootStore.mapStore.mapView, // normally should switch between mapView and sceneView but works anyway?!
              fullTimeExtent: {
                start: startDate,
                end: endDate,
              },
              timeExtent: {
                start: new Date(endDate),
                end: new Date(endDate),
              },
              labelFormatFunction: (value, type, element, layout) => {
                // console.log("labelFormatFunction");
                this.timeSliderLabelFormat(
                  timeSlider,
                  value,
                  type,
                  element,
                  layout
                );
              },
            });
            this.setLastTimeSlider(timeSlider);

            const layer = this.rootStore.regionPrecipitationStore.timeWMSLayer;

            // this.rootStore.mapStore.sceneView
            view.whenLayerView(layer).then((lv) => {
              timeSlider.stops = {
                dates: timestamps,
              };
              timeSlider.tickConfigs = this.ticksLableConfig(
                timestamps,
                startDate,
                endDate
              );
            });

            timeSlider.watch("timeExtent", (value) => {
              this.handleAnimationDate(value.end);
            });
            resolve(timeSlider);
          } else {
            console.log("Keine 'Timestamps' vorhanden ");
            this.setPrecipitationWMSError(true);
            setShowTimeSlider(false);
            resolve(timestamps);
          }
        })
        .catch((e) => {
          console.error("Fehler beim laden der Daten.");
          throw e;
        })
        .finally(() => {});
    });
  };

  timeSliderLabelFormat = (timeSlider, value, type, element, layout) => {
    if (!timeSlider.fullTimeExtent) {
      element.setAttribute(
        "style",
        "font-family: 'Orbitron', sans-serif; font-size: 11px; color: black;"
      );
      element.innerText = "loading...";
      return;
    }
    switch (type) {
      case "min":
      case "max":
        element.setAttribute("style", "text-align: center; ");
        element.innerText = this.getDateFormat.format(value);
        break;
      case "extent":
        // const start = timeSlider.timeExtent.start;
        element.setAttribute(
          "style",
          // "line-height: 18px;font-size: 16px; color: #0079c1; font-weight: bolder;display: flex; flex-flow: column nowrap; align-items: center;text-align: center;")
          "line-height: 18px; font-size: 14px; color: #0079c1; font-weight: bolder; text-align: center;"
        );
        // const elem01 = document.createElement("div");
        // const elem02 = document.createElement("div");
        // elem01.setAttribute("id", "extentElem01");
        // elem02.setAttribute(
        //   "style",
        //   "font-family: monospace; "
        // );
        // elem02.setAttribute("id", "extentElem02");
        // elem01.innerText = selectedTS.label;
        // elem02.innerText = getDateFormat.format(value[0]);
        element.innerText =
          this.selectedTS.label + "\n" + this.getDateFormat.format(value[0]);
        // const element01 = document.getElementById("extentElem01");
        // const element02 = document.getElementById("extentElem02");
        // if (!element01) {
        //   element.append(elem01);
        // } else {
        //   element01.innerText = selectedTS.label;
        // }
        // if (!element02) {
        //   element.append(elem02);
        // } else {
        //   element02.innerText = getDateFormat.format(value[0]);
        // }
        break;
      // no default
    }
  };

  isSomeTicksLabelEqual = (ticksValues) => {
    const tickDaysMonths = ticksValues.map(
      (tick) => new Date(tick).getDate() + "." + new Date(tick).getMonth()
    );
    const hasDuplicate = tickDaysMonths.some(
      (value, i) => tickDaysMonths.indexOf(value) !== i
    );
    return hasDuplicate;
  };

  getEveryNth = (arr, nth) => {
    const result = [];
    for (let i = 0; i < arr.length; i += nth) {
      result.push(arr[i]);
    }
    return result;
  };

  ticksLableConfig = (timestamps, startDate, endDate) => {
    if (this.selectedTS.dateformat === "MMMM y") {
      const { isMobileView } = this.rootStore.uiStore;
      const allTicksValues = timestamps.map((date) => new Date(date).getTime());
      const everyThirdTicksValues = this.getEveryNth(allTicksValues, 3);
      const config = [
        {
          mode: "position",
          values: isMobileView
            ? everyThirdTicksValues
            : timestamps.map((date) => new Date(date).getTime()),
          labelsVisible: true,
          labelFormatFunction: (value) => {
            const formatedDate = this.getTickDateFormat().format(
              new Date(value)
            );
            const formatedLabel = formatedDate.substr(
              0,
              formatedDate.indexOf(".") !== -1 ? formatedDate.indexOf(".") : 4
            );
            return formatedLabel;
          },
        },
      ];
      return config;
    } else {
      console.log(">>>> ticksLableConfig Not Monthly");
      let currentDate = new Date(startDate).getTime();
      const diff = (endDate - startDate) / 3;
      currentDate += diff / 2;
      const ticksValues = [currentDate];
      const lastDate = new Date(endDate).getTime();
      while (currentDate < lastDate) {
        currentDate += diff;
        if (currentDate < lastDate) {
          ticksValues.push(new Date(currentDate).getTime());
        }
      }
      const config = [
        {
          mode: "position",
          values: ticksValues,
          labelsVisible: true,
          labelFormatFunction: (value) => {
            const formatedLabel = this.getTickDateFormat(ticksValues).format(
              new Date(value)
            );
            return formatedLabel;
          },
          tickCreatedFunction: (value, tickElement, labelElement) => {
            tickElement.style.backgroundColor = "gray";
            tickElement.style.width = "2px";
            tickElement.style.height = "8px";
          },
        },
        {
          mode: "percent",
          values: 1,
        },
      ];
      return config;
    }
  };

  setLastTimeSlider = (timeslider) => {
    this.lastTimeSlider = timeslider;
  };

  getTickDateFormat(ticksValues) {
    let formatOptions;
    if (this.selectedTS.dateformat === "MMMM y") {
      formatOptions = { year: "numeric", month: "short" };
    } else if (ticksValues) {
      const tickshasDuplicateDays = this.isSomeTicksLabelEqual(ticksValues);
      if (tickshasDuplicateDays) {
        formatOptions = {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
        };
      } else {
        formatOptions = { year: "2-digit", month: "2-digit", day: "2-digit" };
      }
    }
    const formatDate = new Intl.DateTimeFormat("de-DE", formatOptions);
    return formatDate;
  }

  get getDateFormat() {
    let formatOptions;
    if (this.selectedTS.dateformat === "MMMM y") {
      formatOptions = { year: "numeric", month: "long" };
      // var intervalUnit = "months"
    } else {
      formatOptions = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };
      // var intervalUnit = "hours"
    }
    const formatDate = new Intl.DateTimeFormat("de-DE", formatOptions);
    return formatDate;
  }

  get getIntervalUnit() {
    let intervalUnit;
    if (this.selectedTS.dateformat === "MMMM y") {
      intervalUnit = "months";
    } else {
      intervalUnit = "hours";
    }
    return intervalUnit;
  }

  callBackStaticWMSLayerImViewer = () => {
    this.handleWMSLayerDate();
    this.handleWMSLayerUpdate(true);
  };

  handleDisplayTypeChange = (event) => {
    // this.setPrecipitationWMSError(!this.precipitationWMSError);
    this.selectedDisplayType = event.target.value;
    if (
      this.selectedDisplayType === this.displayTypes[0] &&
      !this.selectedTS.deactive
    ) {
      console.log("#####");
      this.callBackStaticWMSLayerImViewer();
    }
  };

  setRGB = (newRGB) => {
    this.rgb = newRGB;
  };

  removeWMSLayerFromViews = (mapView, sceneView, wmsLayer) => {
    this.watcher?.forEach((watcher) => watcher.remove());
    this.watcher = [];
    if (wmsLayer) {
      // mapView.map.remove(wmsLayer);
      sceneView.map.remove(wmsLayer);
    }
  };

  addWMSLayerToViews = (mapView, sceneView, wmsLayer) => {
    if (wmsLayer) {
      this.watcher?.forEach((watcher) => watcher.remove());
      this.watcher = [];
      this.watcher.push(
        this.pointerMoveEvent2D(this.rootStore.mapStore.mapView)
      );
      this.watcher.push(
        this.pointerLeaveEvent(this.rootStore.mapStore.mapView)
      );
      this.watcher.push(
        this.pointerMoveEvent3D(this.rootStore.mapStore.sceneView)
      );
      // mapView.map.add(wmsLayer);
      sceneView.map.add(wmsLayer);
    }
    this.rootStore.mapStore.putAllLayersOnTop();
  };

  pointerMoveEvent2D = (view) => {
    return view.on("pointer-move", (event) => {
      view.hitTest(event).then((hitTestResult) => {
        const viewRotation = this.rootStore.mapStore.mapView.rotation;
        view.whenLayerView(this.timeWMSLayer).then((lv) => {
          // this.getColorByPosition2D(event, view, viewRotation);
          this.handleHitTest(event, view, viewRotation);
        });
      });
    });
  };

  pointerMoveEvent3D = (view) => {
    return view.on("pointer-move", (event) => {
      view.hitTest(event).then((hitTestResult) => {
        const groundResult = hitTestResult.ground;
        if (groundResult?.mapPoint) {
          view.whenLayerView(this.timeWMSLayer).then((lv) => {
            this.getColorByPosition3D(groundResult.mapPoint, view);
          });
        } else {
          this.setRGB(null);
        }
      });
    });
  };

  pointerLeaveEvent = (view) => {
    return view.on("pointer-leave", (event) => {
      setTimeout(() => {
        this.setRGB(null);
      }, 750);
    });
  };

  handleHitTest = (event, view, viewRotation) => {
    const outSpatialReference = this.timeWMSLayer?.spatialReference; //4326
    const timeWMSLayerFullExtent = this.timeWMSLayer?.fullExtent;
    if (outSpatialReference && timeWMSLayerFullExtent) {
      this.handlePointProjection(
        event,
        view,
        outSpatialReference,
        timeWMSLayerFullExtent,
        viewRotation
      );
    } else {
      this.getColorByPosition2D(event, view, viewRotation);
    }
  };

  handlePointProjection = (
    event,
    view,
    outSpatialReference,
    timeWMSLayerFullExtent,
    viewRotation
  ) => {
    const mapPoint = view.toMap(event);
    if (mapPoint.spatialReference.wkid === outSpatialReference.wkid) {
      this.handleLegendColors(
        mapPoint,
        timeWMSLayerFullExtent,
        event,
        view,
        viewRotation
      );
    } else {
      projection.load().then((evt) => {
        const pGeom = projection.project(mapPoint, outSpatialReference);
        this.handleLegendColors(
          pGeom,
          timeWMSLayerFullExtent,
          event,
          view,
          viewRotation
        );
      });
    }
  };

  isMousePositionOverTimeWMSLayer = (point, extent) => {
    const { xmax, xmin, ymax, ymin } = extent;
    if (
      point &&
      point.x > xmin &&
      point.x < xmax &&
      point.y > ymin &&
      point.y < ymax
    ) {
      // inside WMS-Layer extent
      return true;
    } else {
      // outside WMS-Layer extent
      return false;
    }
  };

  handleLegendColors = (point, extent, event, view, viewRotation) => {
    if (this.isMousePositionOverTimeWMSLayer(point, extent)) {
      this.getColorByPosition2D(event, view, viewRotation);
    } else {
      this.setRGB(null);
    }
  };

  updateWMSLayer = (mapView, sceneView) => {
    this.timeWMSLayer.updateTSURL(this.selectedTS.tsid, this.wmsLayerDate);
    this.timeWMSLayer.setOpacity(this.wmsLayerOpacity / 100);
    this.addWMSLayerToViews(mapView, sceneView, this.timeWMSLayer);
    this.lastWMSLayer = this.timeWMSLayer;
    const { isThreeDMode } = this.rootStore.mapStore;
    if (isThreeDMode) {
      sceneView.whenLayerView(this.timeWMSLayer).then((lv) => {
        this.loadingWMSLayer = false;
      });
    } else {
      mapView.whenLayerView(this.timeWMSLayer).then((lv) => {
        this.loadingWMSLayer = false;
      });
    }
  };

  handleWMSLayerUpdate = (isCheckTimestampsNeeded) => {
    // console.log('this.wmsLayerDate in handleWMSLayerUpdate', this.wmsLayerDate);
    const { mapView, sceneView } = this.rootStore.mapStore;
    this.removeWMSLayerFromViews(mapView, sceneView, this.lastWMSLayer);
    if (!this.selectedTS.deactive) {
      if (isCheckTimestampsNeeded) {
        return new Promise((resolve, reject) => {
          this.loadingWMSLayer = true;
          this.timeWMSrasterVlaues.setToDate(this.wmsLayerDate);
          this.timeWMSrasterVlaues.setPeriod("P3H");
          this.timeWMSrasterVlaues
            .load(this.selectedTS.tsid, "P3H")
            .then(({ timestamps, tsId }) => {
              if (this.isWMSrasterAvailable(timestamps)) {
                this.updateWMSLayer(mapView, sceneView);
                resolve(this.lastWMSLayer);
              } else {
                console.log("Keine Raster vorhanden ");
                this.setPrecipitationWMSError(true);
                this.loadingWMSLayer = false;
                this.lastWMSLayer = false;
                resolve(timestamps);
              }
            })
            .catch((e) => {
              console.error("Fehler beim laden der Daten.");
              throw e;
            })
            .finally(() => {});
        });
      } else {
        this.updateWMSLayer(mapView, sceneView);
      }
    } else {
      this.setPrecipitationWMSError(false);
      // this.loadingWMSLayer = false;
    }
  };

  setSelectedTS = (newTS) => {
    this.selectedTS = newTS;
    this.handleWMSLayerDate();
    this.handleWMSLayerUpdate(true);
  };

  synchronizeMonthAndYearForDate = (newDate, dateInDatePicker) => {
    dateInDatePicker.setYear(newDate.getFullYear());
    dateInDatePicker.setMonth(newDate.getMonth());
  };

  handleTodayDateEvent = (newValue) => {
    const today = new Date();
    const todayDateLocalString = today.toLocaleDateString();
    if (todayDateLocalString === newValue.toLocaleDateString()) {
      console.log("Heute");
      if (today.getHours() === newValue.getHours()) {
        console.log("Zeit");
        newValue.setHours(newValue.getHours() - 1);
      }
    }
    // this.todayDate = newValue;
  };

  handlePrecipitationDate = (newDate) => {
    this.handleTodayDateEvent(newDate);
    this.setMinutesAndSeconds(newDate);
    if (this.selectedTS.dateformat === "MMMM y") {
      this.monthPrecipitationDate = newDate;
      // this.setHoursForMonthlyDate(this.monthPrecipitationDate);
      this.synchronizeMonthAndYearForDate(newDate, this.precipitationDate);
    } else {
      this.precipitationDate = newDate;
      this.synchronizeMonthAndYearForDate(newDate, this.monthPrecipitationDate);
    }
    this.setHoursForMonthlyDate(this.monthPrecipitationDate);
    this.monthPrecipitationDate.setDate(1);

    this.handleWMSLayerDate();
    this.handleWMSLayerUpdate(true);
  };

  handleAnimationDate = (newDate) => {
    // console.log('AnimationDate 111: ', newDate);
    if (this.selectedTS.dateformat === "MMMM y") {
      this.setHoursForMonthlyDate(newDate);
      newDate.setDate(1);
    }
    // console.log('AnimationDate 222: ', newDate);
    var epoch = newDate.getTime();
    this.wmsLayerDate = epoch;
    this.handleWMSLayerUpdate();
  };

  handleWMSLayerDate = () => {
    const wmsDate =
      this.selectedTS.dateformat === "MMMM y"
        ? this.monthPrecipitationDate
        : this.precipitationDate
        ? this.precipitationDate
        : this.todayDate;
    // var temp = new Date(wmsDate);
    // temp = Number(temp);
    // var epoch = temp - (temp % (24 * 3600000));
    // // var epoch = temp - (temp % 3600000);
    const epoch = wmsDate.getTime();
    this.wmsLayerDate = epoch;
  };

  isWMSrasterAvailable = (timestamps) => {
    const epoch1 = new Date(timestamps[timestamps.length - 1]).getTime();
    const epoch2 = new Date(this.wmsLayerDate).getTime();
    return epoch1 === epoch2;
  };

  handleTransparency = (e, newOpacity) => {
    this.wmsLayerOpacity = newOpacity;
    if (this.lastWMSLayer) this.lastWMSLayer.opacity = newOpacity / 100;
  };

  getCSSColor = (rgb, a) => {
    var b = rgb % 256;
    var g = ((rgb - b) / 256) % 256;
    var r = (((rgb - b) / 256 - g) / 256) % 256;
    return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
  };

  renderLegend = (colorClassification, rgb) => {
    return colorClassification.map((color) => {
      let borderStyle = "solid";
      let cssColor = this.getCSSColor(color.rgb, color.alpha);
      if (color.rgb === 0 && color.alpha === 255) {
        cssColor = "rgba(226, 226, 226, 0.5)";
        borderStyle = "dotted";
      }
      return (
        <div
          key={"color" + color.index}
          style={{
            width: "150px",
            position: "relative",
            overflow: "hidden",
            background: color.rgb === rgb ? this.highlightBackground : "white",
          }}
        >
          <div
            style={{
              minWidth: "30px",
              float: "left",
              clear: "left",
              marginBottom: "2px",
              marginTop: "2px",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "15px",
                border: "1px " + borderStyle + " #000",
                background: cssColor,
              }}
            ></div>
          </div>
          <div
            style={{
              float: "left",
              minWidth: "50px",
              height: "15px",
              paddingLeft: "5px",
              textAlign: "left",
            }}
          >
            {color.description}
          </div>
        </div>
      );
    });
  };

  getRGB = (rgb) => {
    var b = rgb % 256;
    var g = ((rgb - b) / 256) % 256;
    var r = (((rgb - b) / 256 - g) / 256) % 256;
    return { r: r, g: g, b: b };
  };

  tollerance = (a, b, c) => {
    return Math.abs(a - b) < c;
  };

  noDataTollerance_3D = (a, b, c) => {
    return Math.abs(a - b) === c;
  };

  highlightLegend = (r, g, b) => {
    const colors = this.timeWMSLayer.wmsColors;
    for (var i = 0; i < colors.length; i++) {
      var color = this.getRGB(colors[i].rgb);
      if (
        (this.tollerance(color.r, r, 10) &&
          this.tollerance(color.g, g, 10) &&
          this.tollerance(color.b, b, 10)) ||
        (this.noDataTollerance_3D(color.r, r, 255) &&
          this.noDataTollerance_3D(color.g, g, 255) &&
          this.noDataTollerance_3D(color.b, b, 255))
      ) {
        this.setRGB(colors[i].rgb);
      }
    }
  };

  isMousePositionInsideView = (e, view) => {
    const screenPointToMapPoint = view.toMap(e);
    const { xmax, xmin, ymax, ymin } = view.extent;
    if (
      screenPointToMapPoint &&
      screenPointToMapPoint.x > xmin &&
      screenPointToMapPoint.x < xmax &&
      screenPointToMapPoint.y > ymin &&
      screenPointToMapPoint.y < ymax
    ) {
      console.log(e);
      console.log(xmax, xmin, ymax, ymin);
      console.log(screenPointToMapPoint?.x, screenPointToMapPoint?.y);
      return true;
    } else {
      return false;
    }
  };

  calculateRadianRotaionAngle = (viewRotation) => {
    let angle;
    if (viewRotation <= 180) {
      if (viewRotation <= 90) {
        angle = viewRotation;
      } else if (viewRotation <= 180 && viewRotation > 90) {
        angle = 180 - viewRotation;
      }
    } else {
      const tempAngle = Math.abs(360 - viewRotation);
      if (tempAngle <= 90) {
        angle = tempAngle;
      } else if (tempAngle <= 180 && tempAngle > 90) {
        angle = 180 - tempAngle;
      }
    }
    const radianRotaionAngle = (angle * Math.PI) / 180;
    return radianRotaionAngle;
  };

  // wmsLayerImageParametersTest = (radianAngle, view) => {
  //   const topLeftPoint = view.toMap({ x: 0, y: 0 });
  //   const topRightPoint = view.toMap({ x: view.width, y: 0 });
  //   const bottomLeftPoint = view.toMap({ x: 0, y: view.height });
  //   const bottomRightPoint = view.toMap({ x: view.width, y: view.height });

  //   const xMinBottom = bottomLeftPoint?.x;
  //   const xMinTop = topLeftPoint?.x;
  //   const extentXMin =
  //     xMinBottom && xMinBottom < xMinTop ? xMinBottom : xMinTop;

  //   const xMaxBottom = bottomRightPoint?.x;
  //   const xMaxTop = topRightPoint?.x;
  //   const extentXMax =
  //     xMaxBottom && xMaxBottom > xMaxTop ? xMaxBottom : xMaxTop;
  //   // const minPointExtent = view.toMap({ x: 0, y: view.height });
  //   // const maxPointExtent = view.toMap({ x: view.width, y: 0 });
  //   let extent = view.extent.clone();
  //   extent.xmin = extentXMin ? extentXMin : extent.xmin;
  //   extent.xmax = extentXMax ? extentXMax : extent.xmax;
  //   extent.ymin = bottomLeftPoint ? bottomLeftPoint.y : extent.ymin;
  //   extent.ymax = topRightPoint ? topRightPoint.y : extent.ymax;
  //   const extentHeight = extent.ymax - extent.ymin;
  //   const extentWidth = extent.xmax - extent.xmin;
  //   const screenEnd = view.toScreen({
  //     x: extent.xmax,
  //     y: extent.ymin,
  //     spatialReference: {
  //       wkid: 2056,
  //     },
  //   });
  //   const screenBegin = view.toScreen({
  //     x: extent.xmin,
  //     y: extent.ymax,
  //     spatialReference: {
  //       wkid: 2056,
  //     },
  //   });
  //   // const height = Math.floor(screenEnd.y - screenBegin.y);
  //   // const width = Math.floor(screenEnd.x - screenBegin.x);

  //   const { xmin, xmax, ymin, ymax } =
  //     view.allLayerViews.items[1].layer.fullExtent;
  //   const aspectRatio = (xmax - xmin) / (ymax - ymin);

  //   const height = view.height;
  //   const width = parseInt(view.height * aspectRatio, 10);
  //   return { height, width, extent };
  // };

  wmsLayerImageParameters2D = (radianAngle, view) => {
    const height = Math.floor(
      Math.cos(radianAngle) * view.height + Math.sin(radianAngle) * view.width
    );
    const width = Math.floor(
      Math.cos(radianAngle) * view.width + Math.sin(radianAngle) * view.height
    );
    const newExtentHeight = (view.extent.height * height) / view.height;
    const newExtentWidth = (view.extent.width * width) / view.width;
    const xMin = view.extent.center.x - newExtentWidth / 2;
    const xMax = view.extent.center.x + newExtentWidth / 2;
    const yMin = view.extent.center.y - newExtentHeight / 2;
    const yMax = view.extent.center.y + newExtentHeight / 2;
    let extent = view.extent.clone();
    extent.xmin = xMin;
    extent.xmax = xMax;
    extent.ymin = yMin;
    extent.ymax = yMax;
    return { height, width, extent };
  };

  getColorByPosition2D = (e, view, viewRotation) => {
    const radianAngle = this.calculateRadianRotaionAngle(viewRotation);
    const { extent, width, height } = this.wmsLayerImageParameters2D(
      radianAngle,
      view
    );
    if (this.timeWMSLayer) {
      this.timeWMSLayer
        .fetchImage(extent, width, height, {
          rotation: viewRotation,
        })
        .then((wmsImg) => {
          const canvas = this.canvas;
          canvas.width = view.width;
          canvas.height = view.height;
          const context = canvas.getContext("2d");
          context.clearRect(0, 0, canvas.width, canvas.height);
          this.drawRotated(e, context, wmsImg, canvas, viewRotation);
        });
    }
  };

  drawRotated = (e, context, wmsImg, canvas, viewRotation) => {
    const x = e.x;
    const y = e.y;
    const timeWMSLayer = this.timeWMSLayer;
    const highlightLegend = this.highlightLegend;
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate((viewRotation * Math.PI) / 180);
    // context.drawImage(wmsImg, 0, 0, wmsImg.width, wmsImg.height);
    context.drawImage(wmsImg, -wmsImg.width / 2, -wmsImg.height / 2);
    try {
      const color = context.getImageData(x, y, 1, 1).data;
      context.strokeStyle = "green";
      context.lineWidth = 5;
      context.strokeRect(x - canvas.width / 2, y - canvas.height / 2, 1, 1);
      if (timeWMSLayer._legend) {
        highlightLegend(color[0], color[1], color[2]);
      }
    } catch (e) {
      //cross Origin
      console.log("WMS color CORS");
    }
  };

  wmsLayerImageParameters3D = (view) => {
    const groundLayer = view.map.ground.layers.getItemAt(0);
    const extent = groundLayer.fullExtent; //view.allLayerViews.items[1].layer.fullExtent;
    const { xmin, xmax, ymin, ymax } = extent;
    let height;
    let width;
    const aspectRatio = (xmax - xmin) / (ymax - ymin);
    if (this.timeWMSLayer) {
      height = parseInt(this.timeWMSLayer?.imageMaxHeight / aspectRatio, 10);
      width = this.timeWMSLayer?.imageMaxWidth;
    }
    if (!height && !width) {
      height = view.height;
      width = parseInt(view.height * aspectRatio, 10);
    }
    return { height, width, extent };
  };

  getColorByPosition3D = (mapPoint, view) => {
    const { extent, width, height } = this.wmsLayerImageParameters3D(view);
    if (this.timeWMSLayer) {
      this.timeWMSLayer.fetchImage(extent, width, height).then((wmsImg) => {
        // const canvas2 = document.getElementById("canvas-test");
        // canvas2.width = width;
        // canvas2.height = height;
        // const context2 = canvas2.getContext("2d");
        // context2.clearRect(0, 0, canvas2.width, canvas2.height);
        // this.getColorAndHighlight(mapPoint, context2, wmsImg, canvas2, extent);

        const canvas = this.canvas;
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        this.getColorAndHighlight(mapPoint, context, wmsImg, canvas, extent);
      });
    }
  };

  calulatePointPositionOnCanvas = (mapPoint, extent, canvas) => {
    const hitX = mapPoint.x;
    const hitY = mapPoint.y;
    const extentXmin = extent.xmin;
    const extentXmax = extent.xmax;
    const extentYmin = extent.ymin;
    const extentYmax = extent.ymax;

    const deltaX = hitX - extentXmin;
    const deltaY = hitY - extentYmin;
    const extentWidth = extentXmax - extentXmin;
    const extentHeight = extentYmax - extentYmin;

    const xOnCanvas = (deltaX / extentWidth) * canvas.width;
    const yOnCanvas = (1 - deltaY / extentHeight) * canvas.height;
    return { xOnCanvas, yOnCanvas };
  };

  getColorAndHighlight = (mapPoint, context, wmsImg, canvas, extent) => {
    const timeWMSLayer = this.timeWMSLayer;
    const highlightLegend = this.highlightLegend;
    const calulatePointPositionOnCanvas = this.calulatePointPositionOnCanvas;
    context.drawImage(wmsImg, 0, 0, wmsImg.width, wmsImg.height);

    try {
      const { xOnCanvas, yOnCanvas } = calulatePointPositionOnCanvas(
        mapPoint,
        extent,
        canvas
      );
      const color = context.getImageData(xOnCanvas, yOnCanvas, 1, 1).data;
      context.strokeStyle = "green";
      context.lineWidth = 5;
      context.strokeRect(xOnCanvas, yOnCanvas, 1, 1);
      if (timeWMSLayer._legend) {
        highlightLegend(color[0], color[1], color[2]);
      }
    } catch (e) {
      //cross Origin
      console.log("WMS color CORS");
    }
  };
}
export default RegionPrecipitationStore;
