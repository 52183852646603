import { Chip, Divider, Box } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import RootStore from "stores/RootStore";

const PrecipitationLegend = ({ classes, dividerTextAlign }) => {
  const {
    timeWMSLayer,
    colorClassificationBaseLink,
    selectedTS,
    // setRasterLegend,
    renderLegend,
    rgb,
  } = RootStore.regionPrecipitationStore;
  const { proxyURL } = RootStore.config;
  const [legend, setLegend] = useState([]);
  const [isLoadingLegendInfos, setLoadingLegendInfos] = useState(true);
  const [colors, setcolors] = useState([]);

  useEffect(() => {
    return new Promise((resolve, reject) => {
      fetch(
        proxyURL +
          colorClassificationBaseLink.replace("$name", selectedTS.legend)
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              const colors = json[0]["color"];
              console.log("loadColorclassification");
              setcolors(colors);
              resolve(colors);
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          throw e;
        })
        .finally(() => {
          setLoadingLegendInfos(false);
        });
    });
  }, [selectedTS, proxyURL, colorClassificationBaseLink]);

  useEffect(() => {
    if (isLoadingLegendInfos === false) {
      const renderedLegend = renderLegend(colors, rgb);
      setLegend(renderedLegend);
      // setRasterLegend(renderedLegend);
      timeWMSLayer.setWMSLayerColors(colors);
      timeWMSLayer.setWMSLayerLegend(renderedLegend);
    }
    // }, [isLoadingLegendInfos, rgb, colors, timeWMSLayer, renderLegend]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingLegendInfos, rgb, colors]);

  return (
    <Box key="precipitation_legend" id="precipitationLegend">
      <Divider className={classes.divider} textAlign={dividerTextAlign}>
        <Chip className={classes.chip} label="Legende[mm]" />
      </Divider>
      <Box sx={{ mt: "5px" }}>{legend}</Box>
      {/* <div style={{ overflowX: "scroll" }}>
        <canvas
          style={{ border: "1px solid red" }}
          id="canvas-test"
          width="100%"
          height="100%"
        ></canvas>
      </div>
      <br></br> */}
    </Box>
  );
};

export default observer(PrecipitationLegend);
