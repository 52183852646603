import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import SceneLayer from "@arcgis/core/layers/SceneLayer";
import StatisticDefinition from "@arcgis/core/rest/support/StatisticDefinition.js";
import Query from "@arcgis/core/tasks/support/Query";
import { observable, action, makeObservable } from "mobx";
class ClimateStore {
  noClimateTopic = {
    id: 9999,
    name: "Kein Klimathema",
    deactive: true,
  };

  selectedClimateTopic = undefined;

  selectedClimateLayerId = undefined;

  climateImageLayer = undefined;

  windSpeedSceneLayer = undefined;

  windSpeedFeatureLayer = undefined;

  windSpeedStatisitcs = undefined;

  coldAirProductionRateLayer = undefined;

  windReferenceScale = 4000;

  climateLayerOpacity = 70;

  loading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      selectedClimateTopic: observable,
      selectedClimateLayerId: observable,
      climateImageLayer: observable,
      climateLayerOpacity: observable,
      loading: observable,
      windSpeedSceneLayer: observable,
      // windSpeedFeatureLayer: observable,
      coldAirProductionRateLayer: observable,
      setSelectedClimateTopic: action,
      setSelectedClimateLayerId: action,
      handleClimateTopicChange: action,
      handleClimateLayerChange: action,
      manageClimateImageLayer: action,
      handleTransparency: action,
      setLoading: action,
      showWindSpeedLayer: action,
      hideWindSpeedLayer: action,
      setWindSpeedStatisitcs: action,
      showColdAirProductionRateLayer: action,
      hideColdAirProductionRateLayer: action,
    });

    this.initStore();
  }

  initStore = () => {
    this.setLoading(true);
    this.allClimateTopics = [this.noClimateTopic].concat(
      this.rootStore.climateRasterLayersConfig
    );

    // console.log(
    //   "%c  allClimateTopics ",
    //   "background: lightblue",
    //   this.allClimateTopics
    // );

    this.allSubLayers = this.rootStore.climateRasterLayersConfig
      .map((config) => config.subLayers)
      .flat();

    // console.log("allSubLayers", this.allSubLayers);

    const { climateScenariosConfigURL, extraClimateLayersConfig } =
      this.rootStore.config;

    this.climateImageLayer = new MapImageLayer({
      url: climateScenariosConfigURL, // "https://www.ag.ch/geoportal/rest/services/alg_klimaanalyse_zukunftszenario/MapServer",
      title: "Climate Image Layer",
      visible: true,
      sublayers: this.allSubLayers,
      opacity: this.climateLayerOpacity / 100,
      listMode: "hide",
    });

    const {
      windSpeedSceneLayerConfig,
      windSpeedFeatureLayerConfig,
      coldAirProductionRateLayerConfig,
    } = extraClimateLayersConfig;

    this.windSpeedSceneLayer = new SceneLayer({
      title:
        windSpeedSceneLayerConfig?.title ||
        "Windrichtung und -Geschwindigkeit 3D",
      // url: "https://arcgis.geo.ag.ch/server/rest/services/Hosted/KKBoNahWf050/SceneServer/layers/0",
      portalItem: windSpeedSceneLayerConfig.portalItem,
      // popupEnabled: false,
      visible: false,
      listMode: "hide",
      minScale: 35000,
    });

    this.windSpeedFeatureLayer = new FeatureLayer({
      title:
        windSpeedFeatureLayerConfig?.title ||
        "Windrichtung und -Geschwindigkeit 2D",
      url: windSpeedFeatureLayerConfig.url,
      // source: graphics,
      // objectIdField: "OBJECTID",
      // geometryType: "point",
      // spatialReference: {
      //   wkid: 2056,
      // },
      visible: false,
      listMode: "hide",
      minScale: 35000,
      renderer: {
        type: "simple",
        symbol: {
          type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
          // Arrow marker
          // path: "M14.5,29 23.5,0 14.5,9 5.5,0z",
          path: "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z",
          color: "#0c5ada",
          outline: {
            color: "#0c5ada",
            width: 0.5,
          },
          angle: 180,
          size: 15,
        },
        visualVariables: [
          {
            type: "rotation",
            field: "WR",
            rotationType: "geographic",
          },
          {
            type: "size",
            field: "WG",
            minDataValue: 0,
            maxDataValue: 4,
            minSize: {
              type: "size",
              valueExpression: "$view.scale",
              // adjust the min size by scale
              stops: [
                { value: this.windReferenceScale, size: 8 },
                { value: this.windReferenceScale * 2, size: 6 },
                { value: this.windReferenceScale * 4, size: 4 },
                { value: this.windReferenceScale * 8, size: 2 },
              ],
            },
            maxSize: {
              type: "size",
              valueExpression: "$view.scale",
              // adjust the max size by scale
              stops: [
                { value: this.windReferenceScale, size: 32 },
                { value: this.windReferenceScale * 2, size: 24 },
                { value: this.windReferenceScale * 4, size: 10 },
                { value: this.windReferenceScale * 8, size: 6 },
              ],
            },
          },
          // {
          //   type: "color",
          //   field: "WG",
          //   stops: [
          //     { value: 0.5, color: "#2b83ba" },
          //     { value: 1, color: "#abdda4" },
          //     { value: 1.5, color: "#ffffbf" },
          //     { value: 2, color: "#fdae61" },
          //     { value: 2.5, color: "#d7191c" },
          //   ],
          // },
        ],
      },
    });

    // this.windSpeedFeatureLayer = this.rootStore.windSpeedFeatureLayer;
    this.getWindSpeedStatistics();

    this.coldAirProductionRateLayer = new MapImageLayer({
      title:
        coldAirProductionRateLayerConfig?.title || "Kaltluftproduktionsrate",
      url: coldAirProductionRateLayerConfig.url,
      visible: false,
      listMode: "hide",
    });

    this.fetchLegend(
      this.coldAirProductionRateLayer,
      coldAirProductionRateLayerConfig.url + "/legend?f=pjson"
      // "https://arcgis.geo.ag.ch/server/rest/services/ALG/Kaltluftproduktionsrate/MapServer/legend?f=pjson"
    );
  };

  setWindSpeedStatisitcs = (stats) => {
    this.windSpeedStatisitcs = stats;
  };

  getWindSpeedStatistics = () => {
    const maxGW = new StatisticDefinition({
      statisticType: "max",
      onStatisticField: "WG", // service field
      outStatisticFieldName: "maxWG",
    });
    const minWG = new StatisticDefinition({
      statisticType: "min",
      onStatisticField: "WG",
      outStatisticFieldName: "minWG",
    });
    const query = new Query();
    query.outStatistics = [maxGW, minWG];
    this.windSpeedFeatureLayer.queryFeatures(query).then((response) => {
      const stats = response.features[0].attributes;
      this.setWindSpeedStatisitcs(stats);
    });
  };

  fetchLegend(layer, legendUrl) {
    return new Promise((resolve, reject) => {
      fetch(
        // "https://arcgis.geo.ag.ch/server/rest/services/ALG/KKBoNahWf050/MapServer/legend?f=pjson"
        legendUrl
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              if (json?.layers.length > 0) {
                // this.windSpeedSceneLayer.legend = json?.layers[0].legend;
                layer.legend = json?.layers[0].legend;
              } else {
                // this.windSpeedSceneLayer.legend = undefined;
                layer.legend = undefined;
              }
              resolve();
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          throw e;
        });
    });
  }

  hideLayerInTargetView = (targetLayer, targetView) => {
    targetView
      .whenLayerView(targetLayer)
      .then(function (layerView) {
        // The layerview for the layer
        layerView.visible = false;
      })
      .catch(function (error) {
        // An error occurred during the layerview creation
      });
  };

  addClimateLayersToMap = (map) => {
    this.addColdAirProductionRateLayer(map);
    this.addClimateImageLayerToMap(map);
    this.addWindSpeedLayerToMap(map);
    this.hideLayerInTargetView(
      this.windSpeedFeatureLayer,
      this.rootStore.mapStore.sceneView
    );
    this.hideLayerInTargetView(
      this.windSpeedSceneLayer,
      this.rootStore.mapStore.mapView
    );
    this.setLoading(false);
  };

  addClimateImageLayerToMap = (map) => {
    map.add(this.climateImageLayer);
  };

  addWindSpeedLayerToMap = (map) => {
    map.add(this.windSpeedSceneLayer);
    map.add(this.windSpeedFeatureLayer);
  };

  addColdAirProductionRateLayer = (map) => {
    map.add(this.coldAirProductionRateLayer);
  };

  showWindSpeedLayer = () => {
    this.windSpeedSceneLayer.visible = true;
    this.windSpeedFeatureLayer.visible = true;
  };

  hideWindSpeedLayer = () => {
    this.windSpeedSceneLayer.visible = false;
    this.windSpeedFeatureLayer.visible = false;
  };

  showColdAirProductionRateLayer = () => {
    this.coldAirProductionRateLayer.visible = true;
  };

  hideColdAirProductionRateLayer = () => {
    this.coldAirProductionRateLayer.visible = false;
  };

  handleTransparency = (e, newOpacity) => {
    this.climateLayerOpacity = newOpacity;
    if (this.climateImageLayer)
      this.climateImageLayer.opacity = newOpacity / 100;
  };

  setLoading = (value) => {
    this.loading = value;
  };

  setSelectedClimateTopic = (newTopic) => {
    this.selectedClimateTopic = newTopic;
    // if (newTopic.deactive) {
    //   this.setSelectedClimateLayerId(undefined);
    // }
  };

  setSelectedClimateLayerId = (id) => {
    this.selectedClimateLayerId = id;
  };

  handleClimateTopicChange = (newTopic) => {
    if (
      !this.selectedClimateTopic ||
      newTopic.id !== this.selectedClimateTopic.id
    ) {
      // console.log("%c >>>>> handleClimateTopicChange", "background: lightblue");
      this.setSelectedClimateTopic(newTopic);
      this.setSelectedClimateLayerId(undefined);
      this.manageClimateImageLayer(undefined);
    }
  };

  handleClimateLayerChange = (sublayerId) => {
    this.setSelectedClimateLayerId(sublayerId);
    this.manageClimateImageLayer(sublayerId);
  };

  // hideClimateImageLayer = () => {
  //   // this.climateImageLayer.title = undefined;
  //   this.climateImageLayer.sublayers = [];
  //   this.climateImageLayer.visible = false;
  // };

  // updatClimateImageLayer = (targetClimateLayer) => {
  //   // this.climateImageLayer.title = targetClimateLayer.name;
  //   this.climateImageLayer.sublayers = [targetClimateLayer];
  //   this.climateImageLayer.visible = true;
  // };

  hideAllSublayers = () => {
    this.climateImageLayer?.sublayers.forEach((element) => {
      element.visible = false;
    });
  };

  manageClimateImageLayer(sublayerId) {
    this.hideAllSublayers();
    if (sublayerId) {
      const targetClimateLayer = this.climateImageLayer?.findSublayerById(
        parseInt(sublayerId)
      );
      if (targetClimateLayer) {
        targetClimateLayer.visible = true;
      }

      // const targetClimateLayer = this.selectedClimateTopic?.subLayers?.find(
      //   (layer) => layer.id === sublayerId
      // );
      // if (targetClimateLayer) {
      //   this.updatClimateImageLayer(targetClimateLayer);
      // } else {
      //   this.hideClimateImageLayer();
      // }

      //   this.rootStore.mapStore.activeView
      //     .whenLayerView(this.climateImageLayer)
      //     .then((lv) => {
      //       console.log("whenLayerView");
      //       this.setLoading(false);
      //     });
    }
  }

  resetClimateWidget = () => {
    this.setSelectedClimateTopic(undefined);
    this.setSelectedClimateLayerId(undefined);
    this.manageClimateImageLayer(undefined);
    this.hideWindSpeedLayer();
    this.hideColdAirProductionRateLayer();
    // this.hideClimateImageLayer();
  };
}

export default ClimateStore;
