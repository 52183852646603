import React, { useEffect } from "react";
import { CircularProgress, Grow, Typography, Box } from "@mui/material";
import { observer } from "mobx-react";
import RootStore from "stores/RootStore";
import { makeStyles } from "@mui/styles";

const loadingElements = (
  <Box
    sx={{
      textAlign: "center",
      pt: 2,
    }}
  >
    <CircularProgress />
    <Typography>Laden der Daten</Typography>
  </Box>
);

const useStyles = makeStyles((theme) => ({
  timeSliderWindow: {
    [theme.breakpoints.up("md")]: {
      left: "5%",
      right: "35%",
      top: "70%",
    },
    [theme.breakpoints.down("md")]: {
      left: "45px",
      right: "45px",
      top: "7%",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "375px",
    },
    [theme.breakpoints.down("sm")]: {
      right: 0,
      minWidth: "310px",
    },
  },
}));

const PrecipitationAnimation = observer((props) => {
  const classes = useStyles();
  const {
    precipitationDate,
    monthPrecipitationDate,
    selectedTS,
    displayTypes,
    selectedDisplayType,
    wmsLayerDate,
    timeWMSrasterVlaues,
    stopLastTimeSlider,
    renderTimeSlider,
    ticksLableConfig,
    lastTimeSlider,
  } = RootStore.regionPrecipitationStore;
  const { activeWidgetId, isMobileView } = RootStore.uiStore;
  const [showTimeSlider, setShowTimeSlider] = React.useState(false);

  useEffect(() => {
    stopLastTimeSlider();
    if (selectedDisplayType === displayTypes[1] && !selectedTS.deactive) {
      setShowTimeSlider(true);
      renderTimeSlider(
        timeWMSrasterVlaues,
        wmsLayerDate,
        selectedTS,
        loadingElements,
        setShowTimeSlider
      );
    } else {
      setShowTimeSlider(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDisplayType,
    selectedTS,
    precipitationDate,
    monthPrecipitationDate,
  ]);

  useEffect(() => {
    if (lastTimeSlider) {
      lastTimeSlider.tickConfigs = ticksLableConfig(
        lastTimeSlider.stops.dates,
        lastTimeSlider.fullTimeExtent.start,
        lastTimeSlider.fullTimeExtent.end
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  return (
    <Grow
      in={showTimeSlider && activeWidgetId === "precipitation"}
      style={{ transformOrigin: "0 0 0" }}
      timeout={{ enter: 1250, exit: 750 }}
    >
      <div
        id="timeSlider"
        style={{
          padding: "4px",
          // bottom: 40,
          borderRadius: "6px",
          // left: "25%",
          // right: "25%",
          margin: "auto",
          position: "absolute",
          zIndex: "9",
          backgroundColor: "rgb(255, 255 ,255 ,0.75)",
          border: "2px solid #0079c1",
          height: "175px",
          marginTop: 0,
        }}
        className={classes.timeSliderWindow}
      ></div>
    </Grow>
  );
});

export default PrecipitationAnimation;
